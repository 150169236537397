'use client';

import { useMemo } from 'react';

import { useUserCommunities } from '@zealy/queries';

import { useCommunitiesFlags } from '#context/FeatureFlags';

export const useMyCommunities = () => {
  const { ff_use_get_user_communities_api_v2 } = useCommunitiesFlags([
    'ff_use_get_user_communities_api_v2',
  ]);
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useUserCommunities(
    undefined,
    undefined,
    ff_use_get_user_communities_api_v2.enabled,
  );
  const userCommunities = useMemo(
    () => data?.pages?.flatMap(page => page.results || []) || [],
    [data],
  );

  return { data: userCommunities, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage };
};
